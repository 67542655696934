import React from "react";
import { Circle } from "@chakra-ui/react";
import { ArrowDownIcon } from "./ArrowDownIcon";

export const ArrowDownCircleIcon = () => {
  return (
    <Circle size="33px" bg="dark.500" color={"gray.default"}>
      <ArrowDownIcon />
    </Circle>
  );
};
