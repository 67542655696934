import React from "react";
import UsersContent from "./users/UsersContent";
import { Route, Routes } from "react-router-dom";
import ProjectsList from "./projects/ProjectsList";
import { useMediaQuery } from "@chakra-ui/react";
import UserModal from "./users/UserModal";

export const SiteRouter = () => {
  const [isMobile] = useMediaQuery("(max-width: 770px)");
  return (
    <Routes>
      <Route path="/" element={<UsersContent isMobile={isMobile} />}>
        <Route
          path="modal/:userMail"
          element={<UserModal isMobile={isMobile} />}
        />
      </Route>
      <Route path="/projects" element={<ProjectsList />}>
        <Route
          path="modal/:userMail"
          element={<UserModal isMobile={isMobile} />}
        />
      </Route>
    </Routes>
  );
};
