import React from "react";
import { Box, Image, Text, Link, Flex } from "@chakra-ui/react";
import { ArrowRight } from "../../icons/ArrowRight";
import moment from "moment";
import { observer } from "mobx-react";

const UserMobileItem = ({ user, isExtendedView }) => {
  return (
    <Box sx={isExtendedView ? extendedUserCardStyles : userCardStyles}>
      <Box sx={isExtendedView ? extendedImageBoxStyles : imageBoxStyles}>
        <Image
          height="100%"
          width="100%"
          src={user.imageUrl}
          fallbackSrc="placeholder.png"
          alt={`${user.firstName} ${user.lastName}`}
          objectFit="cover"
        />
      </Box>

      <Box sx={isExtendedView ? extendedBoxStyles : textBoxStyles}>
        <Text as={"h2"} marginBottom="24px" fontSize={18}>
          {`${user.firstName} ${user.lastName}`}
        </Text>

        <Box as="table" mb="24px">
          <tbody>
            {getGridTextItems(user, isExtendedView).map(
              (item) =>
                !item.hide &&
                item.value && (
                  <tr key={item.label}>
                    <td>
                      <Text
                        textStyle={"label"}
                        as={"span"}
                        minWidth="50px"
                        paddingRight="8px"
                      >
                        {item.label}
                      </Text>
                    </td>
                    <td>
                      {item.label === "Slack" ? (
                        <Link
                          href={`slack://user?team=T354R8S02&id=${user.slackId}`}
                        >
                          Message me
                        </Link>
                      ) : (
                        <Text>{item.value}</Text>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Box>
        {isExtendedView ? (
          <Link
            href={`mailto:${user.email}`}
            dangerouslySetInnerHTML={{ __html: user.wrappedMail }}
          ></Link>
        ) : (
          <Flex alignItems="center">
            <Text as="ins" paddingRight="16px">
              Show more
            </Text>
            <ArrowRight />
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default observer(UserMobileItem);

const getGridTextItems = (user, isExtendedView) => [
  {
    label: "Role",
    value: user.position,
  },
  {
    label: "Office",
    value: user.office,
  },
  {
    label: "Joined",
    value: user.employeeHireDate
      ? moment(user.employeeHireDate).format("MMMM YYYY")
      : "",
    hide: !isExtendedView,
  },
  {
    label: "Department",
    value: user.department,
    hide: !isExtendedView,
  },
  {
    label: "Slack",
    value: user.slackId,
    hide: !isExtendedView,
  },
];

const textBoxStyles = {
  bg: "gray.default",
  padding: "24px",
  height: "100%",
};

const extendedBoxStyles = {
  ...textBoxStyles,
  "@media screen and (min-width: 500px)": {
    padding: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    order: 1,
    height: "initial",
  },
};

const userCardStyles = {
  margin: "auto",
  maxWidth: "310px",
  display: "flex",
  flexDirection: "column",
  height: "100%",
  fontSize: "14px",
};

const extendedUserCardStyles = {
  ...userCardStyles,
  "@media screen and (min-width: 500px)": {
    display: "flex",
    flexDirection: "row",
    margin: "none",
    maxWidth: "none",
  },
};

const imageBoxStyles = {
  height: "210px",
};

const extendedImageBoxStyles = {
  "@media screen and (min-width: 500px)": {
    order: "2",
    width: "40%",
  },
};
