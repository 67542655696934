import React, { useState } from "react";
import { Box, ChakraProvider } from "@chakra-ui/react";
import ChakraTheme from "./styles/ChakraTheme";
import { NavList } from "./components/layout/NavList";
import { SiteRouter } from "./components/SiteRouter";
import { UsersStore } from "./components/users/store/UsersStore";
import { ProjectsStore } from "./components/projects/store/ProjectsStore";
import { UserModalStore } from "./components/users/store/UserModalStore";

export const StoreContext = React.createContext({
  usersStore: undefined,
  projectsStore: undefined,
  userModalStore: undefined,
});

function App() {
  const usersStore = new UsersStore();
  const projectsStore = new ProjectsStore();
  const userModalStore = new UserModalStore(usersStore);
  const [storeContext] = useState({
    usersStore: usersStore,
    projectsStore: projectsStore,
    userModalStore: userModalStore,
  });
  return (
    <StoreContext.Provider
      value={storeContext}
    >
      <ChakraProvider theme={ChakraTheme}>
        <Box textStyle={"body"}>
          <NavList />
          <SiteRouter />
        </Box>
      </ChakraProvider>
    </StoreContext.Provider>
  );
}

export default App;

