import { makeAutoObservable } from 'mobx';

export class ServerAction {
    constructor() {
        makeAutoObservable(this);
    }

    state = 'idle';
    errorResponse = null;

    get isBusy() {
        return this.state === 'ongoing';
    }

    get isIdle() {
        return this.state === 'idle';
    }

    get hasError() {
        return this.state === 'error';
    }

    get error() {
        return this.errorResponse;
    }

    reset() {
        this.state = 'idle';
        this.errorResponse = null;
    }

    execute(func) {
        if (!this.isBusy) return this.exe(func);
    }

    *exe(action) {
        try {
            this.start();
            yield action();
            this.finish();
            return 'success';
        } catch (error) {
            this.handlerError(error);
            return 'error';
        }
    }

    start() {
        this.state = 'ongoing';
    }

    finish() {
        this.state = 'idle';
    }

    handlerError(error) {
        this.state = 'error';
    }
}