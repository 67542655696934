import { Icon } from "@chakra-ui/react";

export const ScrollButtonIcon = () => {
  return (
    <Icon
      viewBox="0 0 48 48"
      w="48px"
      h="48px"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <rect
        width="48"
        height="48"
        transform="translate(48) rotate(90)"
        fill="#222222"
      />
      <path
        d="M23 31L23 32L25 32L25 31L23 31ZM25 31L25 17L23 17L23 31L25 31Z"
        fill="white"
      />
      <path
        d="M17 24L24 17L31 24"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </Icon>
  );
};
