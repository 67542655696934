import { Box } from '@chakra-ui/react';
import React, {useState} from 'react';
import {ScrollButtonIcon} from '../icons/ScrollButtonIcon'  

const ScrollModalButton = ({modalRef}) =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = modalRef.current?.parentElement.parentElement.scrollTop;
    if (scrolled > 40){
      setVisible(true)
    } 
    else if (scrolled <= 40){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    modalRef.current?.parentElement.parentElement.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  modalRef.current?.parentElement.parentElement.addEventListener('scroll', toggleVisible);
  
  return (
    <Box as='button'onClick={scrollToTop} visibility={visible ? 'visible' : 'hidden'} opacity={visible ? '1' : '0'} sx={buttonStyles}>
        <ScrollButtonIcon />
    </Box>
  );
}

const buttonStyles = {
  zIndex: 10,
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  transition: "all 0.3s ease-out",
}
  
export default ScrollModalButton;