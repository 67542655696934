import { extendTheme } from "@chakra-ui/react";

const styles = {
  global: {
  },
};

const colors = {
  dark: {
    200: "#787878",
    500: "#222222",
    600: "#222222",
  },
  gray: {
    default: "#F5F5F5",
    darker: "#E6E6E6",
  },
};

const fonts = {
  body: "Roboto, sans-serif",
};

const textStyles = {
  body: {
    fontSize: ["1rem"],
    fontWeight: 400,
    lineHeight: 1.5,
    color: "#000000",
  },
  h1: {
    fontSize: ["3.75rem"],
    fontWeight: 300,
    lineHeight: 1.1,
  },
  h2: {
    fontSize: ["2.25rem"],
    fontWeight: 400,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: ["1.5rem"],
    fontWeight: 400,
    lineHeight: 1.2,
  },
  label: {
    fontSize: ["1rem"],
    fontWeight: 400,
    lineHeight: 1.875,
    color: "#8F8F8F",
  },
  formLabel: {
    fontSize: ["1rem"],
    fontWeight: 400,
    lineHeight: 1.66,
    paddingBottom: "12px",
  },
};

const sizes = {
  container: {
    md: "1230px",
    lg: "1352px",
  },
};

const Button = {
  baseStyle: {
    fontWeight: "400",
    borderRadius: "none",
  },
  sizes: {
    md: {
      fontSize: "1rem",
      px: 6,
      py: 4,
    },
  },
  variants: {
    solid: {
      height: "44px",
      bg: "dark.500",
      color: "white",
      _hover: { bg: "dark.200" },
    },
    iconButtonBlack: {
      borderRadius: "50%",
      width: "36px",
      height: "36px",
      minWidth: 0,
      padding: 0,
      bg: "black",
      color: "white",
    },
    iconButtonWhite: {
      borderRadius: "50%",
      width: "36px",
      height: "36px",
      minWidth: 0,
      padding: 0,
      bg: "white",
      color: "black",
      _hover: { bg: "gray.darker" },
    },
  },
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

const Badge = {
  baseStyle: {
    textTransform: "none",
  },
};

const Select = {
  baseStyle: {},
  sizes: {},
  variants: {
    outline: {
      field: {
        height: "44px",
        borderRadius: "none",
        borderWidth: "1px",
        borderColor: "dark.500",
      },
      icon: {
        width: "30px",
        "& svg": {
          width: "40px !important",
          height: "40px !important",
        },
      },
    },
  },
  defaultProps: {},
};

const Input = {
  baseStyle: {},
  variants: {
    outline: {
        field: {
          height: "44px",
          borderRadius: "none",
          borderWidth: "1px",
          borderColor: "dark.500",
        }
    }
  }
}

const Container = {
  baseStyle: {
    paddingTop: 10,
    paddingBottom: 10
  }
}

const Modal = {
  baseStyle: {
    dialogContainer: {
      "@supports(height: -webkit-fill-available)": {},
    }
  }
}

const theme = extendTheme({
  styles,
  colors,
  fonts,
  textStyles,
  sizes,
  components: { Button, Badge, Select, Container, Input, Modal },
});

export default theme;
