import React from "react";
import moment from "moment";
import { Box, Link, Image, SimpleGrid, Text, Flex } from "@chakra-ui/react";
import { ArrowDownCircleIcon } from "../../../icons/ArrowDownCircleIcon";
import { observer } from "mobx-react";

const UserListItem = ({ user, inModal }) => {
  return (
    <Flex sx={inModal ? listContainerInModal : listContainer}>
      <Box
        sx={textBoxStyles}
        className="listTextBox"
        width={inModal ? "65%" : "100%"}
        padding={inModal ? "51px 61px" : "25px 40px"}
      >
        <Text as={"h2"} mb={"36px"} fontSize={inModal ? 36 : 24}>
          {`${user.firstName} ${user.lastName}`}
        </Text>
        <SimpleGrid columns={2} spacing={4}>
          {getItems(user).map((item) => {
            switch (item.label) {
              case "Email":
                return (
                  <Box
                    key={item.label}
                    display={item.value ? "intial" : "none"}
                  >
                    <Text textStyle={"label"} as={"span"}>
                      {item.label}
                    </Text>
                    <Box>
                      <Link
                        href={`mailto:${user.email}`}
                        dangerouslySetInnerHTML={{ __html: item.wrappedValue }}
                      ></Link>
                    </Box>
                  </Box>
                );
              case "Slack":
                return (
                  <Box
                    key={item.label}
                    display={item.value ? "intial" : "none"}
                  >
                    <Text textStyle={"label"} as={"span"}>
                      {item.label}
                    </Text>
                    <Box>
                      <Link
                        href={`slack://user?team=T354R8S02&id=${user.slackId}`}
                      >
                        Message me
                      </Link>
                    </Box>
                  </Box>
                );
              default:
                return (
                  <Box
                    key={item.label}
                    display={item.value ? "intial" : "none"}
                  >
                    <Text textStyle={"label"} as={"span"}>
                      {item.label}
                    </Text>
                    <Text>{item.value}</Text>
                  </Box>
                );
            }
          })}
        </SimpleGrid>
        <Box className={"icon"} sx={iconStyles}>
          <ArrowDownCircleIcon />
        </Box>
      </Box>
      <Box width={inModal ? "36%" : "300px"}>
        <Image
          minWidth={inModal ? "none" : "300px"}
          width="100%"
          height="100%"
          src={user.imageUrl}
          fallbackSrc="placeholder.png"
          alt={`${user.firstName} ${user.lastName}`}
          objectFit="cover"
        />
      </Box>
    </Flex>
  );
};
const listContainer = {
  transition: "all 0.3s ease-out",
  gap: "15px",
  _hover: {
    transform: "scale(1.01)",
    "& .listTextBox": {
      bg: "#F5F5F5",
    },
    "& .listImage": {},
  },
};

const listContainerInModal = {
  gap: 0,
  "& .listTextBox": {
    bg: "#F5F5F5",
  },
};

const textBoxStyles = {
  bg: "#E6E6E6",
  position: "relative",
};

const iconStyles = {
  display: "none",
  position: "absolute",
  bottom: "29px",
  right: "26px",
};

const getItems = (user) => [
  {
    label: "Role",
    value: user.position,
  },
  {
    label: "Office",
    value: user.office,
  },
  {
    label: "Email",
    value: user.wrappedMail,
    wrappedValue: user.wrappedMail,
  },
  {
    label: "Slack",
    value: user.slackId,
  },
  {
    label: "Mobile phone",
    value: user.mobilePhone,
  },
  {
    label: "Joined",
    value: user.employeeHireDate
      ? moment(user.employeeHireDate).format("MMMM YYYY")
      : "",
  },
  {
    label: "Department",
    value: user.department,
  },
];

export default observer(UserListItem);
