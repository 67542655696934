import { Icon } from "@chakra-ui/react";

export const GridIcon = () => {
  return (
    <Icon viewBox="0 0 20 20" fill={"none"}>
      <path
        d="M8.33333 2.5H2.5V8.33333H8.33333V2.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.5 2.5H11.6667V8.33333H17.5V2.5Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M17.5 11.6667H11.6667V17.5H17.5V11.6667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.33333 11.6667H2.5V17.5H8.33333V11.6667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
};
