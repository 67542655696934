import { Icon } from "@chakra-ui/react";

export const PlusIcon = () => {
  return (
    <Icon viewBox="0 0 24 24" w='24px' h='24px' fill={"currentColor"}>
      <path
        d="M13 5V4H11V5H13ZM11 19V20H13V19H11ZM11 5V19H13V5H11Z"
        fill="#222222"
      />
      <path
        d="M5 11H4V13H5V11ZM19 13H20V11H19V13ZM5 13H19V11H5V13Z"
        fill="#222222"
      />
    </Icon>
  );
};
