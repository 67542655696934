import UsersDesktopView from "./desktop/UsersDesktopView";
import { AppDropdown } from "../basic/AppDropdown";
import debounce from "lodash.debounce";
import React, { useMemo, useState } from "react";
import { Container, Box, Flex } from "@chakra-ui/react";
import UserMobileView from "./mobile/UsersMobileView";
import { SearchInput } from "../basic/SearchInput";
import { FiltersToggle } from "../basic/FiltersToggle";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { loginAggregateRequest} from "../../authConfig";
import { flowResult } from "mobx";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useContext } from "react";
import { StoreContext } from "../../App";
import LoadingComponent from "../basic/LoadingComponent";
import { Outlet } from "react-router-dom";

function UsersContent({ isMobile }) {
  const { getAccessToken, canAccuireToken } =
    useResponseAccessToken(loginAggregateRequest);
  const { usersStore } = useContext(StoreContext);
  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    if (!canAccuireToken) return;

    (async () => {
      await flowResult(usersStore.loadUsers(getAccessToken));
    })();
  }, [canAccuireToken, getAccessToken, usersStore]);

  const selectedOfficeChanged = (event) => {
    usersStore.setSelectedOffice(event.target.value);
  };

  const selectedDepartmentChanged = (event) => {
    usersStore.setSelectedDepartment(event.target.value);
  };

  const nameSearchTermChanged = (event) => {
    window.navigator.onLine
    ? usersStore.searchForUsers(getAccessToken, event.target.value)
    : usersStore.setNameSearchTerm(event.target.value);
    
  };

  const debouncedNameSearchTermChanged = useMemo(() => {
    return debounce(nameSearchTermChanged, 300);
  }, [getAccessToken]);
  return (
    <>
      <Container maxWidth={"container.md"}>
        <Box>
          <Flex pb={"20px"} flexDir={{ base: "column", md: "row" }}>
            <Box
              width={{ base: "100%", md: "initial" }}
              pr={{ base: "initial", md: "20px" }}
            >
              <SearchInput onChange={debouncedNameSearchTermChanged} />
            </Box>
            <Box pt={{ base: "20px", md: "initial" }}>
              <FiltersToggle
                showFilters={showFilters}
                hideOnClick={() => setShowFilters(false)}
                showOnClick={() => setShowFilters(true)}
              />
            </Box>
          </Flex>

          <Flex
            mb={50}
            gap={{ base: "5px", md: "40px" }}
            flexDirection={{ base: "column", md: "row" }}
            display={showFilters ? "flex" : "none"}
          >
            <Box flex={1}>
              <AppDropdown
                label="Office"
                options={usersStore.uniqueOffices}
                value={usersStore.selectedOffice}
                onChange={selectedOfficeChanged}
              />
            </Box>
            <Box flex={1}>
              <AppDropdown
                label="Team"
                options={usersStore.uniqueDepartments}
                value={usersStore.selectedDepartment}
                onChange={selectedDepartmentChanged}
              />
            </Box>
          </Flex>
          <LoadingComponent
          serverAction={usersStore.loadUsersAction}
          text="Loading users. Please wait..."
          >
            {isMobile ? <UserMobileView /> : <UsersDesktopView />}
          </LoadingComponent>
        </Box>
      </Container>
      <Outlet/>
    </>
  );
}

export default observer(UsersContent);
