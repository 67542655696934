import * as React from "react";
import { NavLink } from "react-router-dom";
import { Tab, TabList, Tabs } from "@chakra-ui/react";
export function NavList() {
  let activeStyle = {
    borderColor: "#2b6cb0",
  };

  return (
    <Tabs>
      <TabList>
        <Tab
          _selected={{ boxShadow: "none" }}
          as={NavLink}
          to="/"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          People
        </Tab>
        <Tab
          _selected={{ boxShadow: "none" }}
          as={NavLink}
          to="/projects"
          style={({ isActive }) => (isActive ? activeStyle : undefined)}
        >
          Projects
        </Tab>
      </TabList>
    </Tabs>
  );
}
