import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { ScrollButtonIcon } from "../icons/ScrollButtonIcon";

const ScrollButton = ({ virtuoso }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    virtuoso.current.scrollToIndex({
      index: 0,
      behavior: "smooth",
      offset: -300
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Box
      as="button"
      onClick={scrollToTop}
      visibility={visible ? "visible" : "hidden"}
      opacity={visible ? "1" : "0"}
      sx={buttonStyles}
    >
      <ScrollButtonIcon />
    </Box>
  );
};

const buttonStyles = {
  zIndex: 3,
  position: "fixed",
  bottom: "20px",
  right: "20px",
  transition: "all 0.3s ease-out",
};

export default ScrollButton;
