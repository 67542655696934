import { Flex, Text } from "@chakra-ui/react";
import React from "react";

export const ToggleButton = ({ text, icon, onClick }) => {
  return (
    <Flex
      as="button"
      alignItems="center"
      onClick={onClick}
    >
      <Text as={"ins"} fontSize="20px" lineHeight="32px" paddingRight="16px">
        {text}
      </Text>
      {icon}
    </Flex>
  );
};
