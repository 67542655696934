import { makeAutoObservable } from "mobx";
import { fetchProjects } from "../../../api/projectsApi"; 
import { ServerAction } from "../../../stores/ServerAction";

export class ProjectsStore {
  constructor() {
    this.fetchProjectsAction = new ServerAction();
    makeAutoObservable(this);
  }

  projects = [];
  isFetched = false;
  selectedClientName = "";
  nameSearchTerm = "";
  uniqueClientsOptions = [];
  SortingOptionsValues = Object.freeze({
    Alphabetically: "0",
    BillableHoursBooked: "1",
  });
  sortingOptions = [
    {
      label: "By Billable Hours Descending",
      value: this.SortingOptionsValues.BillableHoursBooked,
    },
    {
      label: "Alphabetically",
      value: this.SortingOptionsValues.Alphabetically,
    },
  ];
  selectedSortingOption = this.SortingOptionsValues.BillableHoursBooked;

  setSelectedClientName(selectedClientName) {
    this.selectedClientName = selectedClientName;
  }

  setSelectedSortingOption(selectedSortingOption) {
    this.selectedSortingOption = selectedSortingOption;
  }

  setNameSearchTerm(nameSearchTerm) {
    this.nameSearchTerm = nameSearchTerm;
  }

  *fetchProjects(accessToken) {
    yield this.fetchProjectsAction.execute(() => this.fetchProjectsInternal(accessToken));
  }

  *fetchProjectsInternal(accessToken) {
    if (this.projects.length > 0) {
      return;
    }
    const response = yield fetchProjects(accessToken);
    const projects = yield response.json();
    if (!projects) return;
    this.uniqueClientsOptions = this.getUniqueClientsOptions(projects);
    this.sortProjectsMembers(projects);
    this.projects = projects;
    this.isFetched = true;
  }

  get filteredProjects() {
    let filteredProjects = this.projects;

    if (this.selectedClientName !== "") {
      const selectedClientName = this.selectedClientName;
      filteredProjects = filteredProjects.filter(
        (project) => project.clientName === selectedClientName
      );
    }
    if (this.nameSearchTerm.trim() !== "") {
      const nameSearchTerm = this.nameSearchTerm;
      filteredProjects = filteredProjects.filter((project) => {
        const replacedNameSearchTerm = nameSearchTerm.replace(
          /[-\\^$*+?.()|[\]{}]/g,
          "\\$&"
        );
        return (
          project.name.search(RegExp(replacedNameSearchTerm, "i")) > -1 ||
          project.clientName.search(RegExp(replacedNameSearchTerm, "i")) > -1
        );
      });
    }
    const sortedProjects = filteredProjects
      .slice()
      .sort((x, y) => x.name.localeCompare(y.name));
    if (
      this.selectedSortingOption ===
      this.SortingOptionsValues.BillableHoursBooked
    ) {
      sortedProjects.sort(
        (x, y) => y.billableHoursBooked - x.billableHoursBooked
      );
    }
    return sortedProjects;
  }

  sortProjectsMembers = (projects) => {
    projects.forEach((project) => {
      project.members?.sort((x, y) => x.name.localeCompare(y.name));
    });
  };

  getUniqueClients = (projects) => {
    const clientsTypes = [...new Set(projects.map((p) => p.clientName))];
    return clientsTypes.filter(function (clientsTypes) {
      return clientsTypes != null;
    });
  };

  getUniqueClientsOptions = (projects) => {
    const uniqueClients = this.getUniqueClients(projects);
    uniqueClients.sort();
    const clientsOptions = uniqueClients.map((p) => {
      return { label: p, value: p };
    });
    clientsOptions.unshift({ label: "All", value: "" });
    return clientsOptions;
  };
}
