import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCallback } from "react";

export function useResponseAccessToken(request) {
  const { instance, accounts, inProgress } = useMsal();

  const getAccessToken = useCallback(async () => {

    if (!window.navigator.onLine) {
      return {accessToken: null};
    }

    if (inProgress !== InteractionStatus.None) {
      throw new Error(`Cannot aqquire token! Intercation status is: ${inProgress}`);
    }

    if (accounts.length === 0)
      throw new Error(`There are no accounts in msal! Cannot acquire token. }`);

    const functionsRequest = {
      ...request,
      account: accounts[0],
    };

    try {
      const response = await instance.acquireTokenSilent(functionsRequest)
      return response;
    } catch (error) {
      const response = await instance.acquireTokenRedirect(functionsRequest);
      return response;
    }
  }, [instance, accounts, inProgress, request]);

  return { getAccessToken, canAccuireToken: inProgress === InteractionStatus.None };
}