
function createHeaders(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);
  return headers;
}

export async function fetchStorageProjects(accessToken) {
  const options = {
    method: "GET",
    headers: createHeaders(accessToken),
  };
  options.headers.append("ConsistencyLevel", "eventual");

  return fetch("https://forte-book-functions.azurewebsites.net/api/projects", {
    method: "GET",
    headers: createHeaders(accessToken),
  });
}
