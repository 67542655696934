import React from "react";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { PlusIcon } from "../../../icons/PlusIcon";
import { MinusIcon } from "../../../icons/MinusIcon";

export const AccordionMobileItem = ({ accordionHeader, children, display }) => {
  return (
    <Box display={display}>
      <AccordionItem
        mb="48px"
        borderWidth={0}
        borderBottomWidth="0 !important"
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton p={"0 0 16px 0"} borderBottomWidth="2px"  borderColor="#999999">
              <Box flex="1" textAlign="left">
                {isExpanded ? (
                  <Text as={"h3"} textStyle={"label"}>
                    {accordionHeader}
                  </Text>
                ) : (
                  <Text as={"h3"}>{accordionHeader}</Text>
                )}
              </Box>
              {isExpanded ? <MinusIcon /> : <PlusIcon />}
            </AccordionButton>
            <AccordionPanel padding={0}>
              <Box as={"section"} pt="40px">{children}</Box>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Box>
  );
};
