import {
  Container,
  Input,
  Text,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  Box,
  AccordionPanel,
  Wrap,
  WrapItem,
  Badge,
  Flex,
} from "@chakra-ui/react";
import { AppDropdown } from "../basic/AppDropdown";
import debounce from "lodash.debounce";
import React, { useEffect, useMemo } from "react";
import { useContext } from "react";
import { StoreContext } from "../../App";
import { useResponseAccessToken } from "../../hooks/useResponseAccessToken";
import { loginFunctionsRequest } from "../../authConfig";
import { flowResult } from "mobx";
import { observer } from "mobx-react";
import LoadingComponent from "../basic/LoadingComponent";
import { Outlet, useNavigate } from "react-router-dom";

const ProjectsList = () => {
  const { projectsStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const { getAccessToken: getFunctionsAccessToken, canAccuireToken } =
    useResponseAccessToken(loginFunctionsRequest);

  const selectedClientsChanged = (event) => {
    projectsStore.setSelectedClientName(event.target.value);
  };
  const selectedSortingOptionChanged = (event) => {
    projectsStore.setSelectedSortingOption(event.target.value);
  };
  const nameSearchTermChanged = (event) => {
    projectsStore.setNameSearchTerm(event.target.value);
  };

  const debouncedNameSearchTermChanged = useMemo(() => {
    return debounce(nameSearchTermChanged, 300);
  }, []);

  useEffect(() => {
    if (!canAccuireToken) return;
    (async () => {
      const { accessToken } = await getFunctionsAccessToken();
      await flowResult(projectsStore.fetchProjects(accessToken));
    })();
  }, [canAccuireToken, getFunctionsAccessToken, projectsStore]);

  return (
    <>
      <Container maxWidth={"container.md"}>
        <LoadingComponent
          serverAction={projectsStore.fetchProjectsAction}
          text="Loading projects. Please wait..."
        >
          <div>
            <Flex
              mb={50}
              gap={{ base: "5px", md: "40px" }}
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box flex={1}>
                <AppDropdown
                  label="Select sorting option"
                  options={projectsStore.sortingOptions}
                  value={projectsStore.selectedSortingOption}
                  onChange={selectedSortingOptionChanged}
                />
              </Box>
              <Box flex={1}>
                <AppDropdown
                  label="Select client name"
                  options={projectsStore.uniqueClientsOptions}
                  value={projectsStore.selectedClientName}
                  onChange={selectedClientsChanged}
                />
              </Box>
              <Box flex={1} as="label">
                <Text textStyle={"formLabel"}>Search by name</Text>
                <Input onChange={debouncedNameSearchTermChanged} />
              </Box>
            </Flex>
            <Accordion allowToggle>
              {projectsStore.filteredProjects.map((project, index) => (
                <AccordionItem key={index}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        {project.name}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <Box>
                      <Text as={"h3"} textStyle={"label"}>
                        Client name:
                      </Text>
                      <p>{project.clientName}</p>
                    </Box>
                    <Box>
                      <Text as={"h3"} textStyle={"label"}>
                        Project members:
                      </Text>
                      <Wrap>
                        {project.members?.map((member, index) => (
                          <WrapItem key={index}>
                            <Badge
                              as={"button"}
                              onClick={() => {
                                navigate(`/projects/modal/${member.email}`);
                              }}
                            >
                              {member.name}
                            </Badge>
                          </WrapItem>
                        ))}
                      </Wrap>
                    </Box>
                    {project.description && (
                      <Box>
                        <Text as={"h3"} textStyle={"label"}>
                          Description:
                        </Text>
                        <p>{project.description}</p>
                      </Box>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </LoadingComponent>
      </Container>
      <Outlet />
    </>
  );
};

export default observer(ProjectsList);
