function createHeaders(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);
  headers.append(
    "Ocp-Apim-Subscription-Key",
    process.env.REACT_APP_OCP_APIM_KEY
  );
  return headers;
}

export async function fetchAggregateUsersData(accessToken, filter) {
  const options = {
    method: "GET",
    headers: createHeaders(accessToken),
  };

  return fetch(
    filter
    ? `https://apim-forte-dataplatform-prd.azure-api.net/public/aggregate/users?filter=${filter}`
    : 'https://apim-forte-dataplatform-prd.azure-api.net/public/aggregate/users',
    options
  )
    .then((response) => response.json())
    .catch((error) => console.log(error));
}