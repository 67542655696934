import { Box, useMediaQuery } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useContext } from "react";
import { StoreContext } from "../../../App";
import Virtualizer from "../../virtualizer";
import UserData from "../UserData";
import UserMobileItem from "./UserMobileItem";
import { VirtuosoGrid } from "react-virtuoso";
import styled from "@emotion/styled";
import { useRef } from "react";
import ScrollButton from "../../basic/ScrollButton";
const UserMobileView = () => {
  const { usersStore } = useContext(StoreContext);
  const [isTablet] = useMediaQuery("(min-width: 540px)");
  const virtuoso = useRef();
  const ListContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 29px
  `;
  const ItemContainer = styled.div`
  width: calc(50% - 15px);
  `;
  if (isTablet)
    return (
      <>
        <VirtuosoGrid
          ref={virtuoso}
          useWindowScroll
          data={usersStore.filteredUsers}
          components={{
            List: ListContainer,
            Item: ItemContainer
          }}
          overscan={{ main: 1000, reverse: 1000 }}
          itemContent={(index, user) => {
            return (
              user && (
                <Box key={user.mail} id={user.email.split("@")[0]} height="100%">
                  <UserData user={user} UserItemComponent={UserMobileItem} />
                </Box>
              )
            );
          }}
        />
        <ScrollButton virtuoso={virtuoso} />
      </>
    );
  return (
    <Virtualizer
      data={usersStore.filteredUsers}
      increaseViewportBy={{
        top: 300,
        bottom: 300,
      }}
      itemContent={(index, user) => {
        return (
          <Box pb={15}>
            <UserData
              key={index}
              user={user}
              UserItemComponent={UserMobileItem}
            />
          </Box>
        );
      }}
    />
  );
};

export default observer(UserMobileView);
