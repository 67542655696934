import React from "react";
import {
  Box,
  Flex,
  List,
  ListItem,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { SkillBadge } from "../../../basic/SkillBadge";
import { ProjectIcon } from "../../../icons/ProjectIcon";
import { ArrowRight } from "../../../icons/ArrowRight";

export const UserDesktopDetails = ({ user }) => {
  const { aboutMe, interests, skills, currentProjects, pastProjects } =
    user;

  return (
    <Flex p={"48px 61px"} bg={"gray.darker"}>
      <Flex width="67.2%" direction={"column"} paddingRight={interests?.length ? "100px" : "initial"}>
        <Box as={"section"} {...sectionStyles} display={aboutMe ? "initial" : "none"}>
          <Text as={"h3"} textStyle={"label"} {...labelStyles}>
            Three things about me I want you to know
          </Text>
          <p dangerouslySetInnerHTML={{ __html: aboutMe }}></p>
        </Box>
        <Box as={"section"} {...sectionStyles} display={skills?.length ? "initial" : "none"}>
          <Text as={"h3"} textStyle={"label"} {...labelStyles}>
            Skills
          </Text>
          <Wrap>
            {(skills ?? []).map((skill, index) => {
              return (
                <WrapItem key={index}>
                  <SkillBadge text={skill} />
                </WrapItem>
              );
            })}
          </Wrap>
        </Box>
        <Flex gap={"10px"}>
          <Box as={"section"} {...sectionStyles} width={"50%"} display={currentProjects?.length ? "initial" : "none"}>
            <Text as={"h3"} textStyle={"label"} {...labelStyles}>
              Current projects
            </Text>
            <List>
              {(currentProjects ?? []).map((project, index) => {
                return (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    paddingBottom="16px"
                  >
                    <Box paddingRight="18px">
                      <ProjectIcon />
                    </Box>
                    <Text>{project}</Text>
                  </ListItem>
                );
              })}
            </List>
          </Box>

          <Box as={"section"} {...sectionStyles} width={"50%"} display={pastProjects?.length ? "initial" : "none"}>
            <Text as={"h3"} textStyle={"label"} {...labelStyles}>
              Past projects
            </Text>
            <List>
              {(pastProjects ?? []).map((project, index) => {
                return (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    paddingBottom="16px"
                  >
                    <Box paddingRight="18px">
                      <ProjectIcon />
                    </Box>
                    <Text>{project}</Text>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Flex>
      </Flex>
      <Box as={"section"} {...sectionStyles}>
        <Text as={"h3"} textStyle={"label"} {...labelStyles}>
          What I enjoy
        </Text>
        <List>
          {(interests ?? []).map((interest, index) => {
            return (
              <ListItem
                key={index}
                display="flex"
                alignItems="center"
                paddingBottom="24px"
              >
                <Box paddingRight="40px">
                  <ArrowRight />
                </Box>
                <Text>
                {interest}
                </Text>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Flex>
  );
};

const sectionStyles = {
  mb: "30px",
};

const labelStyles = {
  mb: "40px",
};
