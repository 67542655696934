import { Text } from "@chakra-ui/react";
import React from "react";

export const SkillBadge = ({text}) => {
  return (
    <Text sx={skillStyles}>
        {text}
    </Text>
  );
};

const skillStyles = {
    border: '1px solid #222222',
    borderRadius:  '22px',
    padding: '4px 16px'
}