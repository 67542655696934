import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Container,
  Box,
  HStack,
  Collapse,
  Fade,
} from "@chakra-ui/react";
import { UserDesktopDetails } from "./UserDesktopDetails";
import UserListItem from "../user-list-view/UserListItem";
import { observer } from "mobx-react";
import { useNavigate, useResolvedPath } from "react-router-dom";

const MODAL_TOP_MARGIN = 50;
const MODAL_BOTTOM_MARGIN = 20;

const UserDesktopModal = ({ isOpen, onClose, user }) => {
  const mainPath = useResolvedPath("../").pathname;
  const navigate = useNavigate();
  const [isOpenDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setOpenDetails(true);
    }, 500);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={async () => {
        await onClose();
        setOpenDetails(false);
        navigate(mainPath);
      }}
      {...modalProps}
    >
      <Fade in={true} transition={{ enter: { duration: 0.5 } }}>
        <ModalOverlay sx={overlayStyles} />
        <ModalContent sx={contentStyles}>
          <Container maxWidth={"container.md"}>
            <Box position={"relative"}>
              <HStack sx={stackStyles}>
                <ModalCloseButton sx={buttonStyles} />
              </HStack>
              <ModalBody sx={bodyStyles}>
                <UserListItem user={user} inModal={true} />
                <Collapse
                  in={isOpenDetails}
                  animateOpacity
                  transition={{ enter: { duration: 0.5 } }}
                >
                  <UserDesktopDetails user={user} />
                </Collapse>
              </ModalBody>
            </Box>
          </Container>
        </ModalContent>
      </Fade>
    </Modal>
  );
};

const modalProps = {
  size: "full",
  motionPreset: "scale ",
};

const overlayStyles = {
  borderRadius: 0,
  backgroundColor: "rgba(34, 34, 34, 0.25)",
};

const contentStyles = {
  mt: MODAL_TOP_MARGIN,
  mb: MODAL_BOTTOM_MARGIN,
  bg: "transparent",
  boxShadow: "none",
  pointerEvents: "none",
  minHeight:
    "100vh !important" /* to have no horizontal flickering when scrollbar hides and appears */,
};

const bodyStyles = {
  bg: "white",
  pointerEvents: "auto",
  padding: 0,
  boxShadow: "0px -4px 40px rgba(0, 0, 0, 0.5)",
};

const stackStyles = {
  position: "absolute",
  pointerEvents: "auto",
  top: -50,
  right: 0,
};

const buttonStyles = {
  position: "static",
  borderRadius: "50%",
  background: "dark.500",
  color: "white",
  minWidth: 0,
  width: "36px",
  height: "36px",
};

export default observer(UserDesktopModal);
