import { makeAutoObservable } from "mobx";
import { fetchUsersData } from "../../../api/usersApi";
import { ServerAction } from "../../../stores/ServerAction";
import { UserStore } from "./UserStore";

export class UsersStore {
  constructor() {
    this.loadUsersAction = new ServerAction();
    makeAutoObservable(this);
  }

  users = [];
  selectedOffice = "";
  selectedDepartment = "";
  nameSearchTerm = "";
  isFetched = false;
  uniqueOffices = [];
  uniqueDepartments = [];

  setSelectedOffice(selectedOffice) {
    this.selectedOffice = selectedOffice;
  }

  setSelectedDepartment(selectedDepartment) {
    this.selectedDepartment = selectedDepartment;
  }
  setNameSearchTerm(nameSearchTerm) {
    this.nameSearchTerm = nameSearchTerm;
  }

  get filteredUsers() {
    let filteredUsers = this.users;
    if (this.selectedOffice !== "") {
      const selectedOffice = this.selectedOffice;
      filteredUsers = filteredUsers.filter(function (user) {
        return user.office === selectedOffice;
      });
    }
    if (this.selectedDepartment !== "") {
      const selectedDepartment = this.selectedDepartment;
      filteredUsers = filteredUsers.filter(function (user) {
        return user.department === selectedDepartment;
      });
    }
    if (this.nameSearchTerm.trim() !== "") {
      const nameSearchTerm = this.nameSearchTerm;
      filteredUsers = filteredUsers.filter(function (user) {
        return (
          user.fullNameLowerCase.search(
            RegExp(nameSearchTerm.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"), "i")
          ) > -1
        );
      });
    }
    return filteredUsers;
  }

  *getUserByMail(userMail) {
    const user = this.users.find((u) => u.email === userMail);
    return user;
  }

  *loadUsers(getAccessToken) {
    yield this.loadUsersAction.execute(() =>
      this.loadUsersInternal(getAccessToken)
    );
  }

  *loadUsersInternal(getAccessToken) {
    if (this.users.length > 0) {
      return;
    }
    yield this.getUsers(getAccessToken);
  }

  *searchForUsers(getAccessToken, filter) {
    yield this.loadUsersAction.execute(() =>
    this.getUsers(getAccessToken, filter)
  );
  }

  *getUsers(getAccessToken, filter) {
    const { accessToken } = yield getAccessToken();
    const response = yield fetchUsersData(accessToken, filter);
    const validUsers = this.filterOutUsers(response);
    this.users = validUsers.map((user) => new UserStore(user));
    this.uniqueOffices = this.getUniqueOfficesOptions(validUsers);
    this.uniqueDepartments = this.getUniqueDepartmentsOptions(validUsers);
    this.isFetched = true;
  }

  getUniqueDepartmentsOptions = (users) => {
    const uniqueDepartments = this.getUniqueDepartments(users);
    uniqueDepartments.sort();
    const departmentsOptions = uniqueDepartments.map((department) => {
      return { label: department, value: department };
    });
    departmentsOptions.unshift({ label: "All", value: "" });
    return departmentsOptions;
  };

  getUniqueOfficesOptions = (users) => {
    const uniqueOffices = this.getUniqueOffices(users);
    uniqueOffices.sort();
    const officesOptions = uniqueOffices.map((office) => {
      return { label: office, value: office };
    });
    officesOptions.unshift({ label: "All", value: "" });
    return officesOptions;
  };

  getUniqueDepartments = (users) => {
    const departments = [...new Set(users.map((user) => user.department))];
    return departments.filter(function (department) {
      return department != null;
    });
  };

  getUniqueOffices = (users) => {
    const officeLocations = [...new Set(users.map((user) => user.office))];
    return officeLocations.filter(function (office) {
      return office != null;
    });
  };

  filterOutUsers = (users) => {
    return users
      .filter(function (x) {
        return (
          x.firstName &&
          x.lastName &&
          x.department?.toLowerCase() !== "devices" &&
          !x.department?.includes("#") &&
          x.accountEnabled === true &&
          x.email.includes("fortedigital")
        );
      })
      .map((user) => this.configureUser(user));
  };

  configureUser = (user) => {
    return {
      ...user,
      imageUrl: user.picture ? user.picture : "placeholder.png",
      wrappedMail: user.email.replace("@", "<wbr>@"),
      fullNameLowerCase: (user.firstName + " " + user.lastName).toLowerCase(),
    };
  };
}
