import { makeAutoObservable } from "mobx";
import { ServerAction } from "../../../stores/ServerAction";

export class UserModalStore {
  constructor(usersStore) {
    this.usersStore = usersStore;
    this.fetchUserAction = new ServerAction();
    makeAutoObservable(this);
  }
  usersStore = undefined;
  user = undefined;
  isOpen = false;

  *openModal(userMail) {
    this.isOpen = true;
    this.user = yield this.usersStore.getUserByMail(userMail);
  }

  closeModal = () => {
    this.isOpen = false;
  };
}
