import React from "react";
import { FilterIcon } from "../icons/FilterIcon";
import { MinusIcon } from "../icons/MinusIcon";
import { ToggleButton } from "./ToggleButton";

export const FiltersToggle = ({showFilters, showOnClick, hideOnClick}) => {
  return (
    <>
      {showFilters ? (
        <ToggleButton
          text={"Hide Filters"}
          icon={<MinusIcon />}
          onClick={hideOnClick}
        />
      ) : (
        <ToggleButton
          text={"Show filters"}
          icon={<FilterIcon />}
          onClick={showOnClick}
        />
      )}
    </>
  );
};
