import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React from "react";
import { SearchIcon } from "../icons/SearchIcon";

export const SearchInput = ({ onChange }) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        document.activeElement.blur()
      }}
    >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon />}
          zIndex={0}
          color={"white"}
        />
        <Input placeholder="Name or Surname" onChange={onChange} />
      </InputGroup>
    </form>
  );
};
