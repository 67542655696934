import { Icon } from "@chakra-ui/react";

export const ArrowRight = () => {
  return (
    <Icon
      w="24px"
      h="24px"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M5 11.75H4.25V13.25H5V11.75ZM5 13.25H19V11.75H5V13.25Z"
        fill="#222222"
      />
      <path
        d="M12 5.5L19 12.5L12 19.5"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="square"
      />
    </Icon>
  );
};
