import { makeAutoObservable } from "mobx";

export class UserStore {
  constructor(user) {
    makeAutoObservable(this);
    this.department = user.department;
    this.employeeHireDate = user.dateOfEmployement;
    this.firstName = user.firstName;
    this.fullNameLowerCase = user.fullNameLowerCase;
    this.imageUrl = user.imageUrl;
    this.id = user.id;
    this.position = user.position;
    this.email = user.email;
    this.office = user.office;
    this.lastName = user.lastName;
    this.wrappedMail = user.wrappedMail;
    this.phone = user.phone;
    this.skills = user.skills;
    this.aboutMe = user.aboutMe;
    this.currentProjects = user.projects;
    this.pastProjects = user.pastProjects;
    this.interests = user.interests;
    this.slackId = user.slackId;
  }
  department = "";
  employeeHireDate = null;
  fullNameLowerCase = "";
  firstName = "";
  id = "";
  imageUrl = "";
  position = "";
  email = "";
  phone = null;
  office = "";
  lastName = "";
  wrappedMail = "";
  skills = null;
  aboutMe = "";
  pastProjects = null;
  currentProjects = null;
  interests = null;
  slackId = "";
}
