import { Icon } from "@chakra-ui/react";

export const FilterIcon = () => {
  return (
    <Icon viewBox="0 0 24 24" w={"24px"} h={"24px"}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
