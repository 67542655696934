import React from "react";
import UserData from "../../UserData";
import { useContext } from "react";
import UserListItem from "./UserListItem";
import { observer } from "mobx-react";
import { StoreContext } from "../../../../App";
import { Box } from "@chakra-ui/react";
import Virtualizer from "../../../virtualizer";

const UsersList = () => {
  const { usersStore } = useContext(StoreContext);

  return (
    <Virtualizer
      data={usersStore.filteredUsers}
      increaseViewportBy={{
        top: 600,
        bottom: 600,
      }}
      itemContent={(index, user) => {
        return (
          <Box pb={15}>
            <UserData
              key={index}
              user={user}
              UserItemComponent={UserListItem}
            />
          </Box>
        );
      }}
    />
  );
};

export default observer(UsersList);