export const msalConfig = {
  auth: {
    clientId: "9b217a8c-ed53-48df-af43-d73ad280e1cd",
    authority:
      "https://login.microsoftonline.com/063afd9e-5fcb-48d2-a769-ca31b0f5b443", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: window.location.origin + process.env.PUBLIC_URL ?? '',
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};
// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginAggregateRequest = {
  scopes: process.env.REACT_APP_AGGREGATE_SCOPES.split(',')
};

export const loginFunctionsRequest = {
  scopes: process.env.REACT_APP_FUNCTIONS_SCOPES.split(',')
};