import { Spinner } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";

const LoadingComponent = ({ serverAction, children, text }) => {
  if (serverAction.isIdle) {
    return <>{children}</>;
  }
  return (
    <div>
      <Spinner />
      {text}
    </div>
  );
};

export default observer(LoadingComponent);
