import React from "react";
import {
  Accordion,
  Box,
  List,
  ListItem,
  Wrap,
  WrapItem,
  Text,
} from "@chakra-ui/react";
import { AccordionMobileItem } from "./AccordionMobileItem";
import { ProjectIcon } from "../../../icons/ProjectIcon";
import { SkillBadge } from "../../../basic/SkillBadge";
import { ArrowRight } from "../../../icons/ArrowRight";

export const UserMobileDetails = ({ user }) => {
  const { formattedAboutMe, interests, skills, currentProjects, pastProjects } =
    user;

  return (
    <Box sx={accordionContainerStyles}>
      <Accordion defaultIndex={[]} allowMultiple>
        <AccordionMobileItem
          display={formattedAboutMe ? "initial" : "none"}
          accordionHeader="Three things about me I want you to know"
          children={
            <p dangerouslySetInnerHTML={{ __html: formattedAboutMe }}></p>
          }
        />
        <AccordionMobileItem
          accordionHeader="What I enjoy"
          display={interests?.length ? "initial" : "none"}
          children={
            <List>
              {(interests ?? []).map((interest, index) => {
                return (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    paddingBottom="24px"
                  >
                    <Box paddingRight="16px">
                      <ArrowRight />
                    </Box>
                    <Text>{interest}</Text>
                  </ListItem>
                );
              })}
            </List>
          }
        />
        <AccordionMobileItem
          accordionHeader="Skills"
          display={skills?.length ? "initial" : "none"}
          children={
            <Wrap>
              {(skills ?? []).map((skill, index) => {
                return (
                  <WrapItem key={index}>
                    <SkillBadge text={skill} />
                  </WrapItem>
                );
              })}
            </Wrap>
          }
        />
        <AccordionMobileItem
          display={currentProjects?.length ? "initial" : "none"}
          accordionHeader="Current projects"
          children={
            <List>
              {(currentProjects ?? []).map((project, index) => {
                return (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    paddingBottom="16px"
                  >
                    <Box paddingRight="18px">
                      <ProjectIcon />
                    </Box>
                    <Text>{project}</Text>
                  </ListItem>
                );
              })}
            </List>
          }
        />

        <AccordionMobileItem
          accordionHeader="Past projects"
          display={pastProjects?.length ? "initial" : "none"}
          children={
            <List>
              {(pastProjects ?? []).map((project, index) => {
                return (
                  <ListItem
                    key={index}
                    display="flex"
                    alignItems="center"
                    paddingBottom="16px"
                  >
                    <Box paddingRight="18px">
                      <ProjectIcon />
                    </Box>
                    <Text>{project}</Text>
                  </ListItem>
                );
              })}
            </List>
          }
        />
      </Accordion>
    </Box>
  );
};

const accordionContainerStyles = {
  margin: "auto",
  maxWidth: "310px",
  paddingTop: "48px",
  "@media screen and (min-width: 500px)": {
    margin: "none",
    maxWidth: "none",
  },
};
