import { Icon } from "@chakra-ui/react";

export const MinusIcon = () => {
  return (
    <Icon viewBox="0 0 24 24" w='24px' h='24px' fill={"currentColor"}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M5 12H19"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
