import React, { useRef } from "react";
import { Box, Image } from "@chakra-ui/react";
import UserData from "../../UserData";
import { useContext } from "react";
import UserGridItem from "./UserGridItem";
import { observer } from "mobx-react";
import { StoreContext } from "../../../../App";
import { VirtuosoGrid } from "react-virtuoso";
import styled from "@emotion/styled";
import ScrollButton from "../../../basic/ScrollButton";

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 29px;
`;
const ItemContainer = styled.div`
  width: calc(25% - 22px);
  @media (max-width: 1100px) {
    width: calc(33% - 18px);
  } ;
`;

const UsersGrid = () => {
  const { usersStore } = useContext(StoreContext);
  const virtuoso = useRef();
  return (
    <>
      <VirtuosoGrid
        ref={virtuoso}
        useWindowScroll
        data={usersStore.filteredUsers}
        components={{
          List: ListContainer,
          Item: ItemContainer,
        }}
        overscan={{ main: 1000, reverse: 1000 }}
        itemContent={(index, user) => {
          return (
            user && (
              <Box key={user.mail} id={user.email.split("@")[0]} height="100%">
                <UserData user={user} UserItemComponent={UserGridItem} />
              </Box>
            )
          );
        }}
      />
      <ScrollButton virtuoso={virtuoso} />
    </>
  );
};

export default observer(UsersGrid);
