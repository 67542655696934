import React, { useRef } from "react";
import { Virtuoso } from "react-virtuoso";
import ScrollButton from "./basic/ScrollButton";

export default function Virtualizer({ data, itemContent, increaseViewportBy}) {
  const virtuoso = useRef();
  return (
    <>
      <Virtuoso
        increaseViewportBy={increaseViewportBy}
        ref={virtuoso}
        data={data}
        useWindowScroll
        itemContent={itemContent}
      />
      <ScrollButton virtuoso={virtuoso}/>
    </>
  );
}
