import React, { useContext } from "react";
import UserDesktopModal from "./desktop/modal/UserDesktopModal";
import UserMobileModal from "./mobile/modal/UserMobileModal";
import { observer } from "mobx-react";
import { StoreContext } from "../../App";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const UserModal = ({ isMobile }) => {
  const { userModalStore } = useContext(StoreContext);
  const { usersStore } = useContext(StoreContext);
  const params = useParams();
  const userMail = params.userMail;

  useEffect(() => {
    if (userMail && usersStore.users.length) {
      (async () => {
        await userModalStore.openModal(userMail);
      })();
    }
  }, [userMail, userModalStore, usersStore.users]);

  return (
    <>
      {isMobile ? (
        <UserMobileModal
          isOpen={userModalStore.isOpen}
          onClose={userModalStore.closeModal}
          user={userModalStore.user}
          fetchUserAction={userModalStore.fetchUserAction}
        />
      ) : (
        <UserDesktopModal
          isOpen={userModalStore.isOpen}
          onClose={userModalStore.closeModal}
          user={userModalStore.user}
          fetchUserAction={userModalStore.fetchUserAction}
        />
      )}
    </>
  );
};

export default observer(UserModal);
