import { Icon } from "@chakra-ui/react";

export const ProjectIcon = () => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      w="24px"
      h="24px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.4087 1.76514H1.97852V11.6424H11.4087V1.76514Z"
        fill="#0D3050"
      />
      <path
        d="M21.9995 1.76514H12.5693V11.6424H21.9995V1.76514Z"
        fill="#0D3050"
      />
      <path
        d="M11.4087 12.8574H1.97852V22.7347H11.4087V12.8574Z"
        fill="#0D3050"
      />
      <path
        d="M12.5693 13.7036V22.723H21.1805L12.5693 13.7036Z"
        fill="#0D3050"
      />
      <path
        d="M21.9985 21.8769V12.8574H13.376L21.9985 21.8769Z"
        fill="#0D3050"
      />
    </Icon>
  );
};
