import { Icon } from "@chakra-ui/react";

export const ArrowLeftIcon = () => {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M19 13H20V11H19V13ZM19 11H5V13H19V11Z" fill="#222222" />
      <path
        d="M12 19L5 12L12 5"
        stroke="#222222"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </Icon>
  );
};
