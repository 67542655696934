import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

const UserData = ({ user, UserItemComponent }) => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const buttonRef = useRef(null);

  return (
    <Box ref={ref} width="100%" height="100%">
      <Box
        as={"button"}
        onClick={() => {
          buttonRef.current.style.zIndex = 0;
          navigate(`/modal/${user.email}`);
          setTimeout(() => (buttonRef.current.style.zIndex = "auto"), 500);
        }}
        sx={buttonStyles}
        position="relative"
        ref={buttonRef}
      >
        <UserItemComponent user={user} />
      </Box>
    </Box>
  );
};
const buttonStyles = {
  textAlign: "left",
  position: "relative",
  width: "100%",
  height: "100%",
  _focusVisible: {
    outline: "none",
  },
  _hover: {
    "& .icon": {
      display: "flex",
    },
  },
};

export default observer(UserData);
