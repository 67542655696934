import { Box, Circle } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import UsersGrid from "./user-grid-view/UsersGrid";
import UsersList from "./user-list-view/UsersList";
import { GridIcon } from "../../icons/GridIcon";
import { HamburgerIcon } from "../../icons/HamburgetIcon";
import { observer } from "mobx-react";

export const ViewContext = React.createContext({ isGridView: false });

const UsersDesktopView = () => {
  const [isGridView, setIsGridView] = useState(
    JSON.parse(localStorage.getItem("isGridView"))
  );
  const activateGridView = () => {
    setIsGridView(true);
    localStorage.setItem("isGridView", "true");
  };
  const activateListView = () => {
    setIsGridView(false);
    localStorage.setItem("isGridView", "false");
  };
  let viewButton;
  viewButton = isGridView ? (
    <ListButton onClick={activateListView} />
  ) : (
    <GridButton onClick={activateGridView} />
  );

  return (
    <ViewContext.Provider value={{ isGridView: isGridView }}>
      <Box display={"flex"} justifyContent={"flex-end"} marginBottom={"15px"}>
        {viewButton}
      </Box>
      {isGridView ? <UsersGrid /> : <UsersList />}
    </ViewContext.Provider>
  );
};

function GridButton({ onClick }) {
  return (
    <Circle
      as={"button"}
      onClick={onClick}
      size="40px"
      bg="dark.500"
      color={"gray.default"}
    >
      <GridIcon />
    </Circle>
  );
}

function ListButton({ onClick }) {
  return (
    <Circle
      as={"button"}
      onClick={onClick}
      size="40px"
      bg="dark.500"
      color={"gray.default"}
    >
      <HamburgerIcon />
    </Circle>
  );
}

export default observer(UsersDesktopView);
