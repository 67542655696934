import { Box, Select, Text } from "@chakra-ui/react";

export const AppDropdown = ({ label, value, options, onChange }) => {
  return (
    <Box as="label" flex='1'>
      <Text textStyle={"formLabel"}>{label}</Text>
      <Select value={value} onChange={onChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </Box>
  );
};
