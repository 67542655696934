import React from "react";
import { Box, Image, VisuallyHidden, Text } from "@chakra-ui/react";
import { ArrowDownCircleIcon } from "../../../icons/ArrowDownCircleIcon";
import { observer } from "mobx-react";

const UserGridItem = ({ user }) => {
  return (
    <Box position="relative" sx={mainBoxStyles}>
      <Image
        sx={imageStyles}
        src={user.imageUrl}
        fallbackSrc="placeholder.png"
        alt={`${user.firstName} ${user.lastName}`}
        objectFit="cover"
      />
      <Box sx={gridTextBoxStyles} className="user-grid-text-box">
        <Text as={"h2"} mb={"10px"} fontSize={18}>
          {`${user.firstName} ${user.lastName}`}
        </Text>
        {getGridTextItems(user).map((item) => (
          <Box key={item.label} display="flex" alignItems="center">
            <Text textStyle={"label"} as={"span"} paddingRight="15px">
              {item.hideLabel && <VisuallyHidden>{item.label}</VisuallyHidden>}
              {!item.hideLabel && <>{item.label}</>}
            </Text>
            <Text>{item.value}</Text>
            <Box className="icon" sx={iconStyles} px={"25px"}>
              <ArrowDownCircleIcon />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const mainBoxStyles = {
  transition: "all 0.3s ease-out",
  width: "100%",
  height: "100%",
  _hover: {
    zIndex: "1",
    transform: "scale(1.1)",
    "& .user-grid-text-box": {
      visibility: "visible",
      opacity: 1,
    },
  },
};

const imageStyles = {
  width: "100%",
  aspectRatio: "1 / 1",
};

const iconStyles = {
  display: "none",
  position: "absolute",
  bottom: "23px",
  right: "28px",
  padding: 0,
};

const gridTextBoxStyles = {
  roundedBottom: "md",
  shadow: "2xl",
  position: "absolute",
  top: "70%",
  width: "calc(100% + 1.6px)",
  left: "-0.8px",
  bg: "gray.default",
  px: "35px",
  py: "25px",
  visibility: "hidden",
  opacity: 0,
  padding: "24px 40px 40px 40px",
};

const getGridTextItems = (props) => [
  {
    label: "Role",
    value: props.position,
  },
  {
    label: "Office",
    value: props.office,
  },
];

export default observer(UserGridItem);
