import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  Container,
  Box,
  Collapse,
  Text,
  Flex,
  ModalFooter,
} from "@chakra-ui/react";
import { UserMobileDetails } from "./UserMoblieDetails";
import UserMobileItem from "../UserMobileItem";
import { ArrowLeftIcon } from "../../../icons/ArrowLeftIcon";
import { useRef } from "react";
import ScrollModalButton from "../../../basic/ScrollModalButton";
import { observer } from "mobx-react";
import LoadingComponent from "../../../basic/LoadingComponent";
import { useNavigate, useResolvedPath } from "react-router-dom";

const UserMobileModal = ({
  isOpen,
  onClose,
  user,
  imageUrl,
  fetchUserAction,
}) => {
  const [isOpenDetails, setOpenDetails] = useState(false);
  const mainPath = useResolvedPath("../").pathname;
  const navigate = useNavigate();
  const ref = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      setOpenDetails(true);
    }, 500);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={async () => {
        await onClose();
        setOpenDetails(false);
        navigate(mainPath);
      }}
      size="full"
    >
      <ModalContent>
        <Container maxWidth={"container.md"} ref={ref}>
          <Box position={"relative"}>
            <ModalBody padding={0}>
              <LoadingComponent
                serverAction={fetchUserAction}
                text="loading modal user..."
              >
                <Flex
                  marginBottom="40px"
                  as="button"
                  onClick={async () => {
                    await onClose();
                    setOpenDetails(false);
                    navigate(mainPath);
                  }}
                >
                  <ArrowLeftIcon />
                  <Text paddingLeft="13px">Back</Text>
                </Flex>
                <UserMobileItem
                  user={user}
                  imageUrl={imageUrl}
                  isExtendedView={true}
                />

                <Collapse
                  in={isOpenDetails}
                  animateOpacity
                  transition={{ enter: { duration: 0.5 } }}
                >
                  <UserMobileDetails user={user} />
                </Collapse>
              </LoadingComponent>
            </ModalBody>
          </Box>
        </Container>
        <ModalFooter></ModalFooter>
        <ScrollModalButton modalRef={ref} />
      </ModalContent>
    </Modal>
  );
};

export default observer(UserMobileModal);
